<template>
    <div class="p-grid p-jc-center  no_margin" >
        <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
            <div class="p-col-12 p-grid p-field text-left">
                <h1>{{ $t('inviteUserView.title') }}</h1>
            </div>
            <div  class="p-col-12 p-grid p-field text-left" v-html="$t('inviteUserView.info')"> </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px" for="fieldId">{{$t('inviteUserView.organizationName')}}</label>
                <Dropdown class="p-col no_padding" v-model="CompanyList" :options="companyList" optionLabel="Name" :filter="true"  :emptyMessage="$t('inviteUserView.companyEmptyMessage')"
                />
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px" for="departmentId">{{$t('inviteUserView.departmentName')}}</label>
                <Dropdown class="p-col no_padding" v-model="DepartmentList" :options="departmentList" optionLabel="Name" :filter="true" :emptyMessage="$t('inviteUserView.departmentEmptyMessage')" />
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px">{{$t('inviteUserView.email')}}</label>
                <InputText class="p-col" style="padding: 0.5rem 0.5rem;" v-model="Email"  v-bind:class="{ 'p-invalid': invalid }" />
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:180px" >{{$t('inviteUserView.name')}}</label>
                <InputText class="p-col" style="padding: 0.5rem 0.5rem;" v-model="Name"  v-bind:class="{ 'p-invalid': invalid }" />
            </div>
            <div class="p-col-12 text-right p-mb-2">
                {{$t('inviteUserView.inviteEmailText')}}
            </div>
            <div class="p-col-12 text-right">
                <Button class="p-button-outlined p-button-info" @click="inviteUserButtonClick" v-bind:disabled="isProcessing()" >{{$t('inviteUserView.register')}}</Button>
            </div>
        </div>
    </div>
             
</template>

<script>
// @ is an alias to /src
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';

import Chips from 'primevue/chips';
import Processing from '@/mixins/processing'



export default {
    name: 'InviteUser',
    mixins: [Processing],
    components: {
        InputText,
        Button,
        AutoComplete,
        Dropdown,
        Chips,
    },
    data: function() {
        return {
            OrganizationName: null,
            DepartmentName: null,
            Email: null,
            Name: null,
            CompanyList: null,
            filteredCompanyList: null,
            DepartmentList: null,
            filteredDepartmentList: null,
            EmailList: [],
            EmailAddValue: "",
            invalid: false,
            CompanyId: null,
        }
    },

    created: async function() {
        await this.$store.dispatch("userBranchInfo/setMemberList")
    },

    beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const DepartmentId = query.d
        next(vm => vm.setData({DepartmentId}))

    },

    computed: {
        companyList: {
            get: function() {
                const companyList = this.$store.getters["userBranchInfo/getCompanyList"]
                return companyList;
            }
        },
        
        departmentList: {
            get: function() {
                if(this.CompanyList) {
                    let departmentList = this.$store.getters["userBranchInfo/getDepartmentList"]
                    return departmentList.filter(x => x.CompanyId == this.CompanyList._id)
                }
            }
        },
    },

    methods: {
        setData(params) {
            const DepartmentId = params.DepartmentId
            if(DepartmentId) {
                const companyList = this.$store.getters["userBranchInfo/getCompanyList"]
                const departmentList = this.$store.getters["userBranchInfo/getDepartmentList"]
                const department = departmentList.find(x => DepartmentId == x._id)
                const company = companyList.find(x => x._id == department.CompanyId)
                if(company) {
                    this.CompanyList = company
                    if(department) {
                        this.DepartmentList = department
                    }
                }
            }
        },

        companyItemSelect(e) {
            const value = e.value
            console.log(value)
            this.CompanyId = value._id
            this.$refs.CompanyField.onBlur()

        },
        async searchDepartment(event) {
            const query = event.query
            this.filteredDepartmentList = []
            this.$store.commit("userBranchInfo/setDepartmentListByQuery", query)
            let member = this.$store.getters["userBranchInfo/getDepartmentListInSuggesting"]
            member = member.filter(x => x.CompanyId == this.CompanyId)
            this.filteredDepartmentList.splice(0, this.filteredDepartmentList.length, ...member)
        },
        async searchCompany(event) {
            const query = event.query
            this.$store.commit("userBranchInfo/setCompanyListByQuery", query)
            const companyList = this.$store.getters["userBranchInfo/getCompanyListInSuggesting"]
            this.filteredCompanyList = []
            this.filteredCompanyList.splice(0, this.filteredCompanyList.length, ...companyList)
        },
        addEmailList(e){ 
            const value = e.value
            const regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
            if(!regexp.test(value)) {
                const index = this.EmailList.findIndex(x => x == value)
                if(index != -1) {
                    this.EmailList.splice(index, 1)
                }
            }
        },
        async inviteUserButtonClick() {
            const valid = this.isValidEmailList()
            this.startProcessing()
            if(valid) {
                const CompanyList = this.CompanyList
                const DepartmentList = this.DepartmentList
                const EmailList = this.EmailList
                const Email = this.Email
                const Name = this.Name
                if(Name && Name.length > this.$constants.maxTextLength) {
                    this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.invalidMaxTextLength"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                } else if(CompanyList && DepartmentList && Email) {
                    const res = await http.post("/user/AddUserInDepartment", {CompanyId: CompanyList._id, DepartmentId: DepartmentList._id, Email: Email, Name: Name})
                    if(res.Success) {
                        this.$toast.add({severity:'success', summary: this.$i18n.tc("invited.registedSuccess"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                        this.CompanyList = null
                        this.DepartmentList = null
                        this.Email = null
                        this.Name = null
                    } else {
                        if(res.MessageList) {
                            this.$store.commit('upgradePopup/setTextList', res.MessageList.map(x => { return {text: x} }))
                            this.$store.commit('upgradePopup/setShow', true)
                            
                        } else if(res.Message) {
                            this.$toast.add({severity:'error', detail: res.Message, life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                        } else {
                            this.$toast.add({severity:'error', detail:this.$i18n.tc("invited.registedFailed"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                        }
                    }
                }
            } else {
                this.$toast.add({severity:'error', detail:this.$i18n.tc("utils.invalidEmailAddress"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.endProcessing()
        },

        onEmailListAddBlur(e) {
            const value = e.target.value
            if(value) {
                this.EmailList.push(value)
            }
            e.target.value = ""
            this.isValidEmailList()
        },

        isValidEmailList(){ 
            const Email = this.Email ? this.Email.trim() : ""
            const regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
            this.Email = Email
            return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(Email)
        },

        onFocusCompanyField(e) {
            console.log(this.$refs.CompanyField)
            this.$refs.CompanyField.onDropdownClick()
        },

        onFocusDepartmentField(e) {
            this.$refs.DepartmentField.onDropdownClick()
        },
    }
}
</script>
